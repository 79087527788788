import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, updateDoc, getFirestore } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

function SuccessLoading() {
    const auth = getAuth();
    const navigate = useNavigate();
    const firestore = getFirestore();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true);  // Forces a state update when the component mounts
    }, []);



    useEffect(() => {
        const grantLifetimeAccess = async (user) => {
            if (!user) return;

            const userDocRef = doc(firestore, "users", user.uid);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
                const userData = userDoc.data();

                if (userData.hasLifetimeAccess) {
                    navigate("/home");
                    return;
                }
            }

            await updateDoc(userDocRef, { hasLifetimeAccess: true });

            const CLIENT_ID = "730839280853-e555l55gu8dh11hrta1bkc6qdp1rl5to.apps.googleusercontent.com";
            const REDIRECT_URI = encodeURIComponent("https://leadcollect.ca/oauth-success");

            const oauthUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=https://www.googleapis.com/auth/gmail.send&access_type=offline&prompt=consent&state=${user.uid}`;

            window.location.href = oauthUrl;
        };

        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                await grantLifetimeAccess(user);
            } else {
                navigate("/landingPage");
            }
        });

        return () => unsubscribe();
    }, [auth, navigate, firestore]);

    if (!loaded) return null;  

    return (
        <div>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <CircularProgress />
                <p>Welcome!</p>
            </Box>
        </div>
    );
}

export default SuccessLoading;
