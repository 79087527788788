import React, { useEffect, useState } from "react"
import NavBar from "./NavBar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAuth,onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import LinkComponent from "./LinkComponent";


function LeadMagnetPage() {
    const { quizName } = useParams(); // Access the quiz name from the route
    const location = useLocation();
    const { index } = location.state || {}; 

    const auth = getAuth();
    const navigate = useNavigate();
    const [quiz, setQuiz] = useState(null);
    const[loading, setLoading] = useState(true);
    const [quizFromDb, setQuizFromDb] = useState(null);


useEffect(() => {
  const unsubscribe = onAuthStateChanged(auth, async (user) => {
    if (!user) {
      setLoading(false); 
      return;
    }

    const firestore = getFirestore();
    const docRef = doc(firestore, "users", user.uid);
    const docSnap = await getDoc(docRef);

    const data = docSnap.exists() ? docSnap.data() : null;
    if (!data || !data.quizzes || data.quizzes.length === 0) {
      setLoading(false);
      return;
    }

    const reversedQuizzes = [...data.quizzes].reverse();
    if (index >= reversedQuizzes.length) {
      console.error("Index out of bounds:", index);
      setLoading(false);
      return;
    }

    setQuiz(reversedQuizzes[index]);

    const extractedQuizId = reversedQuizzes[index].link?.split("/quiz/")[1];
    if (!extractedQuizId) {
      console.error("Invalid quiz link format");
      setLoading(false);
      return;
    }

    const quizRef = doc(firestore, "quizzes", extractedQuizId);
    const quizSnap = await getDoc(quizRef);

    if (quizSnap.exists()) {
      setQuizFromDb(quizSnap.data());
    } else {
      console.error("Quiz not found in Firestore");
    }

    setLoading(false);
  });

  return () => unsubscribe(); // Cleanup the listener when component unmounts
}, []);

    

    if (loading) {
      return <div>Loading...</div>;
    }

    const answers = quiz.answers;

    // Extract unique questions for the table headers
    const questions = answers ? (answers[0]?.answers.map((a) => a.question) || []): [];

    const exportAsCSV = () => {
      // Add headers (Email, Name, and questions)
      const headers = ["Email", "Name", ...questions];
    
      // Add data rows
      const rows = answers.reverse().map((lead) => {
        const row = [lead.email, lead.name]; // Start with email and name
        questions.forEach((question) => {
          const answerObj = lead.answers.find((a) => a.question === question);
          let answer = answerObj ? answerObj.answer : "N/A";
          // Escape quotes and wrap values in double quotes
          answer = `"${answer.replace(/"/g, '""')}"`;
          row.push(answer);
        });
        return row;
      });
    
      // Combine headers and rows with semicolon (instead of comma)
      const csvContent = [headers, ...rows]
        .map((row) => row.join(";")) // Use semicolon as separator
        .join("\n"); // Join all rows with newlines
    
      // Trigger download
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "leads.csv");
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    
    

  return (
    <div className="homeStructure">
    <NavBar/>
    <div className="pageContent">
    <div className="dataRow">
        <div className="dataBox">
            <p style={{fontSize:'45px', marginBottom:'-10px', fontWeight:'bold', color:'#4361ee'}}>{answers.length}</p>
            <p>Leads Collected</p>
        </div>
        <LinkComponent link={quiz.link} />
        {/* <div className="dataBox">
            <p style={{fontSize:'45px', marginBottom:'-10px', fontWeight:'bold', color:'#4361ee'}}>{numberOfVisitors}</p>
            <p>Visitors</p>
        </div> */}

    </div>
{answers.length !== 0 && 
<div>
  <Button
        variant="contained"
        color="primary"
        onClick={exportAsCSV}
        style={{ marginBottom: "20px" , width:'250px'}}
      >
       Download CSV
       </Button>
    <TableContainer component={Paper}>
        <Table aria-label="lead answers table">
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Name</TableCell>
              {questions.map((question, index) => (
                <TableCell key={index}>{question}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...answers].reverse().map((lead, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {lead.email}
                </TableCell>
                <TableCell component="th" scope="row">
                  {lead.name}
                </TableCell>
                {questions.map((question, qIndex) => {
                  // Find the answer for the current question
                  const answerObj = lead.answers.find((a) => a.question === question);
                  return (
                    <TableCell key={qIndex}>
                      {answerObj ? answerObj.answer : 'N/A'}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
    }
    {
        answers.length === 0 &&
        <p>The collected leads will be displayed here.</p>
    }
      </div>
    </div>
  )
};

export default LeadMagnetPage;
