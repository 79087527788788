import React, { useEffect, useState } from "react"
import NavBar from "./NavBar";
import { getAuth } from "firebase/auth";
import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";

function CreateLeadMagnet() {
  const auth = getAuth();
  const [businessNiche, setBusinessNiche] = useState("");
  const [websiteLink, setWebsiteLink] = useState("");
  const [leadMagnetName, setLeadMagnetName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [fName, setFName] = useState("");
  const [aha, setAha] = useState("");
  const navigate = useNavigate();


  async function getUserData() {
    if (auth.currentUser !== null)
    {
        const firestore = getFirestore()
        const docRef = doc(firestore, "users", auth.currentUser.uid)
        const docSnap = await getDoc(docRef)
      
        const data = docSnap.exists() ? docSnap.data() : null
      
        if (data === null || data === undefined) return null
        return data;
    }
    }


  const submitLeadMagnet = async (e) => {
    e.preventDefault();
    const data = await getUserData();
    if (data !== null) {
      await updateDoc(doc(db, "users", auth.currentUser.uid), {
        quizzes : [...data["quizzes"], {
          "name": leadMagnetName,
          "businessNiche" : businessNiche,
          "websiteLink" : websiteLink,
          "link": "",
          "json": {},
          "businessName": businessName,
          "fName": fName,
          "aha": aha,
          "answers": [],
          "nbVisitors": 0
        }]
      })
      navigate("/home");
    }
  }

  return (
    <div className="homeStructure">
    <NavBar/>
    <div className="addCampainTile">
        <p className="title">Generate lead magnet</p>
        <form
        onSubmit={submitLeadMagnet}
         >
         <p style={{fontWeight:'bold'}}>1. Business information</p>
         <div className="formElement">
            <p>Lead magnet name:</p>
            <input type="text" name="leadMagnetName" placeholder="Ex: July Campain 1" className="leadMagnetInput" onChange={(value)=> {setLeadMagnetName(value.target.value)}}/>
         </div>
         <div className="formElement">
            <p>Business name:</p>
            <input type="text" name="leadMagnetName" className="leadMagnetInput" onChange={(value)=> {setBusinessName(value.target.value)}}/>
         </div>
        <div className="formElement">
            <p>What are you offering ?<br/>
            <span style={{fontSize:'15px', color:'grey'}}>Indicate your offer and target audience.<br/> Briefly explain why your offer is unique.  </span> 
            </p>
            <textarea 
            type="text" 
            className="bigInput"
            placeholder='Example: "I sell gym programs to people above the age of 30. People can choose duration of the program, from 1 to 6 months, and the prices range from $150 to $600."'
           onChange={(value)=> {setBusinessNiche(value.target.value)}}
             required/>
        </div>
        <div style={{height:'20px'}}/>
        <div className="formElement">
          <p>Your website url:</p>
          <input type="url" name="website" placeholder="The url of the page where leads can buy from you" className="leadMagnetInput" style={{width: '350px'}}
            onChange={(value)=> {setWebsiteLink(value.target.value)}}
            required
          />
        </div>
        <div style={{height:'30px'}}/>
        <div style={{height:'20px'}}/> 
         <p style={{fontWeight:'bold'}}>2. Personal information</p>
        <p style={{color:'grey'}}>This will be used to sign the emails and make them feel more personal. </p>
         <div className="formElement"> 
            <p>Your first name:</p>
            <input type="text" name="leadMagnetName" className="leadMagnetInput" onChange={(value)=> {setFName(value.target.value)}}/>
         </div>

        <div style={{height:'20px'}}/>
        <p>We will create: <br/><b>- A quiz</b> to engage your audience and collect leads<br/><b>- A custom email</b> to deliver value and build interest in your offer<br/>We will automatically send the emails to your leads.</p> 
        <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
        <button className="actionButton">Create Lead Magnet</button>
        </div>
        </form>
      </div>
      </div>
  )
};

export default CreateLeadMagnet;
