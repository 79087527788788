// src/components/Signup.js
import React, { useEffect, useState } from 'react';
import { auth, db, provider } from '../firebaseConfig';
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import './Auth.css';
import { FcGoogle } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';


function Signup() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate(); // Initialize useNavigate
    const provider = new GoogleAuthProvider();


    async function getUserData(uid) {
      console.log(uid)
      const firestore = getFirestore()
      const docRef = doc(firestore, "users", uid)
      const docSnap = await getDoc(docRef)
    
      console.log(docSnap.exists())
      const data = docSnap.exists() ? docSnap.data() : null
    
      if (data === null || data === undefined) return null
      console.log("Data: ",data)
      return data;
  }
  
    const handleSignup = async (e) => {
      e.preventDefault();
      if (password !== confirmPassword) {
        setError("Passwords do not match");
        return;
      }
  
      try {
        const result = await createUserWithEmailAndPassword(auth, email, password);
        const data = await getUserData(result.user.uid)
        if (data === null){
          console.log("oh nooo")
          await setDoc(doc(db, "users", result.user.uid), {
            uid: result.user.uid,
            email: result.user.email,
            quizzes: [],
            hasLifetimeAccess: false
          })
        }    
        navigate('/loading')
      } catch (error) {
        setError(error.message);
      }
    };

    

    const signInWithGoogle = async () => {
      const provider = new GoogleAuthProvider();

      try {
          const result = await signInWithPopup(auth, provider);
          const user = result.user;
  
          const userDocRef = doc(db, "users", result.user.uid);
          const userDoc = await getDoc(userDocRef);
  
          if (!userDoc.exists()) {
              await setDoc(userDocRef, {
                  uid: result.user.uid,
                  email: result.user.email,
                  quizzes: [],
                  hasLifetimeAccess: true,
                  gmailRefreshToken: null
              });
            }
           // requestGmailAuthorization(user.uid);

  
          navigate('/loading');  // Continue to payment or successLoading
      } catch (error) {
          console.error("Google sign-in error:", error);
      }
  };

  const requestGmailAuthorization = async (userId) => {
    const CLIENT_ID = "730839280853-e555l55gu8dh11hrta1bkc6qdp1rl5to.apps.googleusercontent.com";
    const REDIRECT_URI = encodeURIComponent("https://leadcollect.ca/oauth-success");
    
    const oauthUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=https://www.googleapis.com/auth/gmail.send&access_type=offline&prompt=consent&state=${userId}`;

    window.location.href = oauthUrl;
};

  
  return (
    <div className="auth-container">
      <h2>Sign Up</h2>
      <form onSubmit={handleSignup}>
        <div className="input-group">
          <label>Email</label>
          <input type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required />
        </div>
        <div className="input-group">
          <label>Password</label>
          <input  type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Create a password"
            required />
        </div>
        <div className="input-group">
          <label>Confirm Password</label>
          <input type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm your password"
            required />
        </div>
        {error && <p style={{color:'red'}}>{error}</p>}
        <button type="submit">Sign Up</button>
      </form>
      <p>or</p>
      <div className="googleButton" onClick={signInWithGoogle}>
    <FcGoogle size={30}/>
    <div style={{width:'10px'}} />
    <p>Continue with Google</p>
      </div>
      <p>
        Already have an account? <a href="/login">Login here</a>
      </p>
    </div>
  );
}

export default Signup;
