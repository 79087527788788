import React, { useEffect, useState } from "react"
import NavBar from "./NavBar";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, getFirestore } from "firebase/firestore";

function Home() {
    const auth = getAuth();
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const[loading, setLoading] = useState(true)

    useEffect(()=>{
      async function getUserData() {

        if (auth.currentUser !== null)
        {
            const firestore = getFirestore()
            const docRef = doc(firestore, "users", auth.currentUser.uid)
            const docSnap = await getDoc(docRef)
          
            const data = docSnap.exists() ? docSnap.data() : null
          
            if (data === null || data === undefined) return null
            setUserData(data);
            setLoading(false)

        }
      }
      getUserData();

    })

    if (loading) {
      return <div>Loading...</div>;
    }

    const handleQuizClick = (quizName, index) => {
      navigate(`/LeadMagnetPage/${quizName}`, { state: { index } });
    };

  return (
    <div className="homeStructure">
      <NavBar/>
      <div className="homeContent">
        <p className="title">My lead magnets</p>
        <div className="createCampainBtn" onClick={()=> navigate("/createLeadMagnet")}><p >+ Create Lead Magnet</p></div>
        {userData != null &&
          [...userData.quizzes].reverse().map((quiz, index) => {
          return <div className="campainTile"
          key={index}
          onClick={() => handleQuizClick(quiz.name, index)}
          >
            <p className="campainName">{quiz.name}</p>
          </div>
        })
        }
      </div>
    </div>
  )
};

export default Home;
