import React, { useEffect } from "react"
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { getAuth, onAuthStateChanged  } from "firebase/auth";
import { doc, getDoc, getFirestore, collection, addDoc, onSnapshot } from "firebase/firestore";
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from "react-router-dom";


function Loading() {
    const auth = getAuth();
    const navigate = useNavigate();

     const firestore = getFirestore();
     const unsubscribe = onAuthStateChanged(auth, async (user) => {
        const buyLifetimeDeal = async () => {
          if (auth.currentUser)
          {
            const userRef = doc(firestore, "users", auth.currentUser.uid)
            const userDoc = await getDoc(userRef);
            if (userDoc.exists() && !userDoc.data()["hasLifetimeAccess"]) {
            const checkoutSessionsRef = collection(userRef, "checkout_sessions");
            const docRef = await addDoc(checkoutSessionsRef, {
                price: "price_1QqP3wGbodjRP3tXxQAkxBn9",
                quantity: "1",
                mode: "payment",
                success_url: "https://leadcollect.ca/success",
                cancel_url: "https://leadcollect.ca/loading"
              });
              onSnapshot(docRef, async (snap) => {
                const { error, sessionId } = snap.data();
        
                if (error) {
                  // Show an error to your customer and
                  // inspect your Cloud Function logs in the Firebase console.
                  alert(`An error occurred: ${error.message}`);
                }
        
                if (sessionId) {
                  const stripe = await loadStripe('pk_live_51QqOTjGbodjRP3tXHodEYhHlrWL2vqXH2ZH1VtOfpIX44LJH0EBjcySXU2vmR9fnva3ewiApeNVRfQSGvEANdQ2g00xbeuWKq2');

                  stripe.redirectToCheckout({sessionId})
                }
              });
            }
            else if(userDoc.exists()) {
              navigate('/home')
             }
         }
         else {
          navigate('/landingPage')
         }
        }
         buyLifetimeDeal();
    })

  return (
    <div>
      <Box sx={{ display: 'flex', flexDirection:'column', alignItems:'center' }}>
      <CircularProgress />
      <p>Please Wait...</p>
    </Box>
    </div>
  )
};

export default Loading;
