import React, { useState } from "react"
import { useNavigate } from 'react-router-dom';
import { PiSignOut  } from "react-icons/pi";
import { getAuth, signOut } from "firebase/auth";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';


function NavBar() {
  const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const [formData, setFormData] = useState({ email: '', object: '' });

    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
      console.log(formData.email+" "+ formData.object)
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        await axios.post('https://sendsupportemail-txulmbdauq-uc.a.run.app', 
          formData,
          { headers: { "Content-Type": "application/json" } }
        );
        alert('Email envoyé avec succès !');
        handleClose();
      } catch (error) {
        alert('Erreur lors de l\'envoi de l\'email.', error);
      }
    };
  
    

    const navigate = useNavigate();
    const auth = getAuth();
 
    const handleLogout = () => {
        signOut(auth)
          .then(() => {
            console.log("User signed out successfully.");
            navigate("/")
          })
          .catch((error) => {
            console.error("Error signing out: ", error);
          });
      };

  return (
    <div className="menuRow">
        <p className="logo" onClick={()=> navigate('/home')}>Lead<span style={{color:'#4361ee'}}>Collect</span></p>
        <div className="rightButtons">
            <Button className="manageSub" variant="outlined" onClick={handleClickOpen}>Feedback/Support</Button>
            <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Contact us</DialogTitle>
      
          <form onSubmit={handleSubmit}>
          <DialogContentText>
            If you're giving feedback (we appreciate it !) or you need support, fill this form and indicate your request. It will be a pleasure to help you ! We will come back to you in under 2 business days.
          </DialogContentText>
      <DialogContent>
        <TextField
          autoFocus
          required
          margin="dense"
          name="email"
          label="Email Address"
          type="email"
          fullWidth
          variant="standard"
          value={formData.email}
          onChange={handleChange}
        />
        <TextField
          required
          margin="dense"
          name="object"
          label="Object of your message"
          type="text"
          fullWidth
          variant="standard"
          multiline
          maxRows={4}
          minRows={2}
          value={formData.object}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit">Send email</Button>
      </DialogActions>
    </form>
      </Dialog>
            <PiSignOut color="red" size={40} className="signOut" onClick={handleLogout}/>
        </div>
    </div>
  )
};

export default NavBar;
