import React from "react"

function FinalPage() {
    return <div className='App'>
    <div style={{ color: 'black', height:'100%', margin:'auto', textAlign:'center'}}>
     <p className='quizTitle'>Thanks for answering!</p>
    <p className='quizDesc'>Check your inbox to get your results.<br/><br/>Also check in the Promotions tabs.</p> 
  </div>
  <a
      href="https://leadcollect.ca"
      target="_blank"
      rel="noopener noreferrer"
      className="poweredBy"
    >
      Powered by LeadCollect
    </a>
  </div>
};

export default FinalPage;
