import { addDoc, collection, doc, increment, updateDoc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react"
import { FaCheck, FaGift,FaTimes, FaPlus  } from "react-icons/fa";
import { IoTimeSharp } from "react-icons/io5";
import { db, auth } from "./firebaseConfig";
import { FaMagnifyingGlassDollar } from "react-icons/fa6";
import leadflow from "./assets/engchart.png"
import screenshot from "./assets/screenshot.png"
import ProcessTile from "./ProcessTile";
import WhatWeDo from "./WhatWeDoTile";
import { grey } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import ReactPlayer from 'react-player/lazy'


function LandingPage() {

  const FAQItem = ({ question, answer, isOpen, onClick }) => (
    <div className={`faq-item ${isOpen ? 'open' : ''}`} onClick={onClick}>
      <div className="faq-question">
        <h3>{question}</h3>
        <div className="faq-icon">
          {isOpen ? <FaTimes /> : <FaPlus />}
        </div>
      </div>
      {isOpen && <div className="faq-answer">{answer}</div>}
    </div>
  );

  const goToSignUp = async () => {
    const docRef = doc(db, "validationStats", "stats");
    await updateDoc(docRef, {
      clickedBuy: increment(1),
    });
    navigate("/signup")
  }

    

    const [hasBeenSeen, setHasBeenSeen] = useState(false); // State to track if the element has been seen

    const handleIntersection = async (entries, observer) => {
        const entry = entries[0];
        if (entry.isIntersecting && !hasBeenSeen) {
            const docRefe = doc(db, 'validationStats', 'stats');

            try {
                setHasBeenSeen(true);

                observer.disconnect();

            } catch (error) {
                console.error("Error updating document: ", error);
            }
        }
    };

    const section1Ref = useRef(null);
    const hasUpdatedSection1 = useRef(false);
    useEffect(() => {
      const observer = new IntersectionObserver(
        async (entries) => {
          const entry = entries[0];
  
          if (entry.isIntersecting && !hasUpdatedSection1.current) {
            try {
              const docRef = doc(db, "validationStats", "stats");
              await updateDoc(docRef, {
                endedFirstSection: increment(1),
              });
              hasUpdatedSection1.current = true;
              observer.disconnect(); // Stop observing once updated
            } catch (error) {
              console.error("Error updating Firestore:", error);
            }
          }
        },
        { threshold: 0.5 } // Trigger when at least 50% of the section is visible
      );
  
      if (section1Ref.current) {
        observer.observe(section1Ref.current);
      }
  
      return () => observer.disconnect();
    }, []);

    useEffect(() => {
      if (sessionStorage.getItem("hasRunLandingPage")) return;
  
      const incrementViews = async () => {
        const docRef = doc(db, "validationStats", "stats");
        await updateDoc(docRef, {
          seeLanding: increment(1),
        });
      };
  
      incrementViews();
      sessionStorage.setItem("hasRunLandingPage", "true");
    
      const observer = new IntersectionObserver(handleIntersection, {
        threshold: 0.1, 
      });
    
      if (sectionRefs.sawOfferStack.current) {
        observer.observe(sectionRefs.sawOfferStack.current);
      }
    
      return () => {
        observer.disconnect();
      };
    }, []);
    



  const scrollToPlans = async (btnIndex) => {
    sectionRefs.sawOfferStack.current.scrollIntoView({ behavior: 'smooth' });
    const docRefe = doc(db, 'validationStats', 'stats');
    const fieldName = `CTA${btnIndex}`;

    // Create an update object with the dynamic field name
    const updateObject = {
        [fieldName]: increment(1)
    };

    //Update the Firestore document
   await updateDoc(docRefe, updateObject);
  };

  const coreOffer = [
    {
      "offer": "Done-for-You Unlimited Quizzes with AI",
      "value": 497 
    },
    {
      "offer": "Unlimited Lead Capture ",
      "value": 377 
    },
    {
      "offer": "Custom Email Sent to Each Lead",
      "value": 297 
    }
  ]

  const bonuses = [
    {
      "bonus": "Free Quiz Hosting ",
      "value": 197 
    },
    {
      "bonus": "Downloadable Lead Data & Answers in CSV Format",
      "value": 87 
    },
  ]
  const totalValue =
  coreOffer.reduce((acc, item) => acc + item.value, 0) +
  bonuses.reduce((acc, item) => acc + item.value, 0);

  const faqs = [
    {
      question: 'Who is this for?',
      answer: "If you’re getting traffic but not enough leads, this is for you. If you want a simple, automated way to capture and follow up with clients, this is for you. If you run ads or create content but feel like you're wasting potential leads, this is for you."
    },
    {
      question: 'How much time does it take to set up?',
      answer: "Less than 2 minutes! Just enter a few details, and our AI generates your quiz instantly—no tech skills needed."
    },
    {
      question: 'How long does it take for the lead to receive the email?',
      answer: "Under 3 minutes. As soon as the lead fills up the quiz, our AI will analyze their answers, craft an email and automatically send it."
    },
    {
      question: 'Can I export my leads?',
      answer: "Yes! You can download all your leads and their responses in a CSV file whenever you want."
    },
    {
      question: 'Can I get my money back?',
      answer: 'Yes, you have 7 days after purchase to request a refund. No questions asked.'
    },
    {
      question: 'I have another question',
      answer: "Feel free to contact us at contact@leadcollect.ca. You will get a response within 48 hours."
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);
  const handleFAQClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const navigate = useNavigate();
  const sectionRefs = {
    sawTheOneMethod: useRef(null),
    sawImage: useRef(null),
    sawPromise: useRef(null),
    sawOfferStack: useRef(null),
    sawFAQ: useRef(null)
  };

  const observedSections = {
    sawTheOneMethod: useRef(false),
    sawImage: useRef(false),
    sawPromise: useRef(false),
    sawOfferStack: useRef(false),
    sawFAQ: useRef(false)
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      async (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            const sectionKey = entry.target.getAttribute("data-section");
            if (sectionKey && !observedSections[sectionKey].current) {
              try {
                const docRef = doc(db, "validationStats", "stats");
                await updateDoc(docRef, {
                  [sectionKey]: increment(1)
                });
                observedSections[sectionKey].current = true;
              } catch (error) {
                console.error("Error updating Firestore:", error);
              }
            }
          }
        }
      },
      { threshold: 0.5 } // Trigger when at least 50% is visible
    );

    Object.keys(sectionRefs).forEach((key) => {
      if (sectionRefs[key].current) {
        observer.observe(sectionRefs[key].current);
      }
    });

    return () => observer.disconnect();
  }, []);

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();

    try {
        const result = await signInWithPopup(auth, provider);
        const user = result.user;

        const userDocRef = doc(db, "users", result.user.uid);
        const userDoc = await getDoc(userDocRef);

        if (!userDoc.exists()) {
            await setDoc(userDocRef, {
                uid: result.user.uid,
                email: result.user.email,
                quizzes: [],
                hasLifetimeAccess: false,
                gmailRefreshToken: null
            });
          }
          //requestGmailAuthorization(user.uid);


        navigate('/loading'); 
    } catch (error) {
        console.error("Google sign-in error:", error);
    }
};


  return (
    <div style={{height:'100vh', display:'flex', flexDirection:'column', textAlign:'center', alignItems:'center', width:'100%'}}>
      <div className="menuRow">
      <p className="logo"><span style={{fontSize:'24px'}}></span>Lead<span style={{color:'#4361ee'}}>Collect</span></p> 
            <button className="landingCTA" style={{marginRight:'15px', width:'150px'}} onClick={()=>scrollToPlans(1)}>Start Now</button>
      </div>
      <div className="heroSection">
      <p className="objective">With this software...</p>
        <p className="heroTitle">Capture 500+ Leads Every Month on Autopilot<br/> With Engagement-Based Lead Magnets</p>
        <p style={{fontSize:'18px'}}>If you don't get leads in 2 weeks, you can ask for a <span className="emphasisSpan">full refund + 3 months free</span>*</p>
        <div className="vslContainer">
        <iframe
        src="https://www.youtube.com/embed/dSaLh5TcuLE"
        title="YouTube Video"
        className="vsl"
      ></iframe>
        </div>
        <button className="landingCTA" style={{ width:'250px', fontSize:'20px', fontFamily:'Poppins', marginBottom:'20px'}} onClick={()=>scrollToPlans(2)}>Give me access now!</button>
      </div>
      <div style={{backgroundColor:'#edf2fb', width:'97vw', display:'flex', flexDirection:'column', alignItems:'center',}}>
      <div style={{maxWidth:'600px', padding:'10px'}}>
        <p className="subSubTitle">Does this sound familiar ?</p>
        <p className="badList">
          "Why can’t I ever get a consistent flow of clients, no matter how hard I try?"<br/><br/>
          "Why does marketing always drain my energy and time with little to show for it?"<br/><br/>
          "How can I automate client acquisition and focus on growing my business?"<br/><br/>
          
          </p>
          <p className="copyText">If this keeps you up at night...<br/><br/>
            <span className="emphasisSpan">You might want to read this.</span><br/><br/>

            In the next few minutes, I’m going to reveal the exact process that helped me <span className="emphasisSpan">3X the number of leads</span> I capture from the same traffic.<br/><br/>

            All while requiring minimal effort.<br/><br/>

            Despite what the classic methods promise, you won’t capture more leads by…<br/><br/></p>
            <p className="badList">
            "Relying on generic e-books that your audience ignores."<br/><br/>
            "Throwing more money at ads that don’t convert."<br/><br/>
            "Hiring overpriced agencies that don’t understand your business."<br/><br/>
            </p>
            <p className="copyText" ref={section1Ref}>There is actually one method that <span className="emphasisSpan">skyrocketed my lead flow</span> (and it can do the same for you).<br/><br/><br/>
            But I found it the hard way...</p>
               <div style={{height:'30px'}}/>
      </div>
      </div>

      <div className="heroSection">
      <div style={{maxWidth:'600px', padding:'10px'}}>
        <p className="subTitle">I tried everything to get more leads...</p>
        <p className="copyText">
        A year ago, I was stuck. I had a mobile app and needed leads.<br/><br/>

        I set up lead magnets, built landing pages, and tested different hooks.<br/><br/><br/>

        But no matter what I did, my conversion rates were disappointing. <br/><br/>

        Visitors would <b>land on my page, take a quick look, and leave.</b> <br/><br/>

        It was frustrating. <br/><br/><br/>

        I knew I had a great offer, but I couldn’t figure out <span className="emphasisSpan">how to make people actually care enough to take action.</span><br/><br/><br/>
        
        I spent months tweaking my landing pages.<br/><br/>

        Rewriting my opt-in copy.<br/><br/>

        Offering free PDFs, checklists, and templates.<br/><br/><br/>

        <b>But nothing seemed to work.</b><br/><br/><br/>

        I even tried tweaking my ads, thinking maybe I wasn’t bringing in the right audience.<br/><br/>

        But after spending more time and money, I realized the problem wasn’t the audience. <span className="emphasisSpan"><b>It was the way I was capturing leads.</b></span>
        </p>
        <div style={{height:'15px'}}/>
        <p className="subTitle" ref={sectionRefs.sawTheOneMethod} data-section="sawTheOneMethod">The One Method That Tripled My Lead Capture</p>
        <p className="copyText">
        I was watching a video from Alex Hormozi as I usually do to find inspiration.<br/><br/>

        Then he said something that hit me:<br/><br/>

        A good lead magnet is something that is better than your competitor’s paid stuff.<br/><br/><br/>

        And it made me realize…<br/><br/>

        <b>People don’t just want generic advice. They want advice <span className="emphasisSpan">that’s tailored to them.</span></b><br/><br/><br/>

        And that’s when everything clicked.<br/><br/><br/>
        I figured out that the most scalable way to collect lead information in an engaging way was with quizzes.<br/><br/><br/>

        <b>So I built a simple quiz. </b><br/><br/>

        Instead of offering another lead magnet, I asked my visitors a few personalized questions about their struggles and goals.<br/><br/><br/>

        Then, using their answers, I manually wrote a tailored email to follow up—addressing their exact pain points with specific solutions.<br/><br/><br/>

        And <b>it worked like magic.</b><br/><br/> My leads actually opted in read my emails. <br/><br/>They took action. <span className="emphasisSpan">Conversions skyrocketed.</span><br/><br/><br/>

        My lead flow <b>tripled.</b><br/><br/>

        <span className="emphasisSpan">Same traffic. Same offer. But <b>3X more leads.</b></span><br/><br/>

        And all of this happened with just a simple shift—from boring, static lead magnets to <b>Engagement-Based Lead Capture.</b><br/><br/></p>
        <img src={leadflow} alt="leadflow" style={{maxWidth:'500px', height:'auto', width:'90%'}} ref={sectionRefs.sawImage} data-section="sawImage"/>
      <p className="copyText">
        But there was one problem...<br/><br/>

        Manually writing emails was incredibly <b>time-consuming.</b> <br/><br/>

        I was spending hours crafting responses instead of growing my business.<br/><br/><br/>

        So I asked myself: <span style={{fontStyle:'italic'}}>What if I could automate this entire process?</span><br/><br/>

        And that’s when I had the second breakthrough.<br/><br/><br/>
        I realized that with AI, I could instantly generate <span className="emphasisSpan">ultra-personalized</span> follow-ups for every lead.<br/><br/><br/>

          So I built a system that does exactly that:<br/><br/>

          1. A quiz that collects the right insights from your leads.<br/><br/>
          2. AI-powered responses that feel like you wrote them yourself.<br/><br/>
          3. A simple process that converts more visitors into action-taking leads—without any extra effort from you.<br/><br/>

          No more guessing what your leads need. <br/><br/>

          <b>No more low conversion rates.</b><br/><br/>

          Just an automated, <span className="emphasisSpan">Engagement-Based System</span> that gets leads to trust you and take action.<br/><br/><br/>

          And now, I’ve made it available for you—so you can finally stop struggling with low conversions and start capturing more leads effortlessly.
        </p>
        </div>
      </div>
      <div className="valueProp">
        <div style={{maxWidth:'600px', padding:'10px', display: 'flex', flexDirection:'column', alignItems:'center'}}>
        <p className="objective">Our goal here is simple...</p>
        <p className="heroTitle" style={{color:'white'}} ref={sectionRefs.sawPromise} data-section="sawPromise">Get you so many leads that you need to stop taking clients.</p>
        {/* <img src={screenshot} alt="leadflow" style={{maxWidth:'1000px', height:'auto', width:'90%'}}/> */}
        <p className="subTitle" style={{fontSize:'23px'}}>The Engagement-Based Lead Capture System</p>
        <ProcessTile text={"Step 1: Tell Us About Your Business (⏳ 3 minutes)"} 
        subtext={"Enter basic details about your business and offer."} />
        <ProcessTile text={"Step 2: Send Visitors to Your Quiz"} 
        subtext={"We provide a unique link—just share it with your audience."} />
        <p className="whatWeDo">What We Do (So You Don’t Have To)</p>
        <WhatWeDo text={"✅ We Build & Host Your Quiz with AI(🕒 Time Saved: 5 hours)"} 
        subtext={"In under a minute, we create a custom quiz designed to engage your visitors. Example: “Get Your 7-Day Custom Gym Protocol”"} />
        <WhatWeDo text={"✅ We Collect & Analyze Lead Data (🕒 Time Saved: 2 hours per lead)"} 
        subtext={"Our AI identifies each lead’s pain points—so you know exactly what they need."} />
        <WhatWeDo text={"✅We Write a Personalized Follow-Up Email with AI (🕒 Time Saved: 3 hours per lead)"} 
        subtext={"For every lead, AI crafts a hyper-personalized email delivering the promised value. Sent instantly."} />

        <p className="subTitle" style={{fontSize:'23px'}}>Total time saved: 5 hours per lead</p>
        <button className="landingCTA" style={{ width:'250px', fontSize:'20px', fontFamily:'Poppins', marginBottom:'20px'}} onClick={()=>scrollToPlans(3)}>Get access now</button>
        </div>
      </div>

        <div className="heroSection">
        <div style={{maxWidth:'600px', padding:'10px', display: 'flex', flexDirection:'column', alignItems:'center'}}>

        <div className="pricing-tile" ref={sectionRefs.sawOfferStack} data-section="sawOfferStack">
      <div className="total-value">
        Total Value: <span className="highlight">${totalValue}</span>
      </div>

      <div className="offers">
        {coreOffer.map((item, index) => (
          <div key={index} className="offer-item">
            <span>✅{item.offer}</span>
            <span className="value">(${item.value} value)</span>
          </div>
        ))}
      </div>

      <div className="bonuses">
        <h3>+ Free $284 Bonuses</h3>
        {bonuses.map((item, index) => (
          <div key={index} className="offer-item">
            <span>🎁{item.bonus}</span>
            <span className="value">(${item.value} value)</span>
          </div>
        ))}
      </div>

      <p>🛡️+ Guarantee: if, after two weeks, you don't capture any leads, you can ask for a <b>full refund</b> AND get <span className="emphasisSpan"><b style={{color:'black'}}>3 months completely FREE*</b></span></p>

      <div className="pricing-details">
        <p className="normal-price">Normal Price: $59/month</p>
        <p className="limited-time">Until Feb. 20th: <span style={{color:'#9ef01a'}}>$97 one-time</span>, keep forever</p>
      </div>
      <button className="landingCTA" style={{ width:'300px', fontSize:'20px', fontFamily:'Poppins', marginBottom:'20px'}} onClick={()=>signInWithGoogle()}>Get lifetime access now</button>

    </div>
    <p style={{color:"grey"}}>* You have 3 days after the two-week mark (so between day 14 and 17 after you get access) to ask for a refund and 3 months of free access.<br/>You can ask for it if you did not generate any leads before day 14.</p>
    <p className="subTitle">Save your spot before it’s too late</p>
        <p className="copyText">
        Think about it. Right now, visitors are landing on your page… <b>and leaving.</b> <br/><br/>

        They’re interested, but you’re not capturing them. <br/><br/>

        That means lost revenue, lost clients, and wasted traffic.<br/><br/><br/>

        And here’s the thing—<b>this won’t fix itself.</b><br/><br/>

        The longer you wait, the more leads you let slip away.<br/><br/><br/>

        But here’s the catch… <span className="emphasisSpan">We’re limiting access.</span><br/><br/><br/>

        To ensure quality results, we’re only onboarding <b>50 businesses</b> at a time. <br/><br/>

        Once we hit our cap, we double the price. <br/><br/><br/>

        If you come back later, you'll might have to pay <b>$197</b>.<br/><br/>

        Now is the time to start turning more visitors into leads—<b>without extra effort.</b><br/><br/>
        </p>
        <div style={{width:'100%', border:'1px solid #ffd9be', backgroundColor:'#ffede1', borderRadius:'13px'}} ref={sectionRefs.sawFAQ} data-section="sawFAQ">
        <p className='titleStytle'>We are ready for your questions</p>
        {faqs.map((faq, index) => (
        <FAQItem
          key={index}
          question={faq.question}
          answer={faq.answer}
          isOpen={openIndex === index}
          onClick={() => handleFAQClick(index)}
        />
      ))}
      </div>
      <button className="landingCTA" style={{ width:'300px', fontSize:'20px', fontFamily:'Poppins', marginBottom:'20px', marginTop:'20px'}} onClick={()=>scrollToPlans(4)}>Get lifetime access now</button>
        </div>
        
        </div>
        <div className="valueProp" style={{height:'200px'}}>
        <div style={{maxWidth:'600px', padding:'10px', display: 'flex', flexDirection:'column', alignItems:'center'}}>
        <a href="/privacyPolicy" style={{fontFamily:'Inter', color: 'grey', fontWeight:'bold', cursor:'pointer'}}>See Privacy Policy</a>
        <p style={{fontFamily:'Inter', color: 'grey'}}>© 2025 LeadCollect. All rights reserved.</p>
        </div>
        </div>      
    </div>
  )
};

export default LandingPage;
