import { useEffect } from "react";


const OAuthSuccess = () => {
    function wait(ms){
        var start = new Date().getTime();
        var end = start;
        while(end < start + ms) {
          end = new Date().getTime();
       }
     }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code");
        const userId = urlParams.get("state");

        if (code && userId) {
            // Send the code to backend function to exchange for tokens
            fetch(`https://googleauth-txulmbdauq-uc.a.run.app`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ code, userId }),
            })
            .then(response => response.json())
            .then(data => {
                console.log("Token exchange success:", data);
            //wait(10000);
                window.location.href = "/home";  // Redirect after success
            })
            .catch(error => console.error("OAuth Error:", error));
        }
    }, []);

    return <div>Processing authentication...</div>;
};

export default OAuthSuccess;
