import React from "react"

function PrivacyPolicy() {
  return (
    <div class="privacy-policy-container">
        <h1>Privacy Policy</h1>
        <p><strong>Effective Date:</strong> 2025-02-10</p>

        <h2>1. Introduction</h2>
        <p>Welcome to LeadCollect ("Company," "we," "our," "us"). Your privacy is important to us, and we are committed to protecting the personal information you share with us. This Privacy Policy explains how we collect, use, and safeguard your information when you use our SaaS product and related services ("Service").</p>
        <p>By using our Service, you agree to the terms of this Privacy Policy. If you do not agree, please do not use our Service.</p>

        <h2>2. Information We Collect</h2>
        <h3>A. Personal Information</h3>
        <ul>
            <li>Name, email address, and phone number (if provided)</li>
            <li>Payment details for subscriptions (processed securely by third-party payment providers)</li>
            <li>Account credentials (username, password, authentication details)</li>
        </ul>
        <h3>B. Business & Usage Data</h3>
        <ul>
            <li>Business name, industry, and other relevant details</li>
            <li>Quiz responses and lead data entered into our platform</li>
            <li>Customer interactions with our Service, including support inquiries</li>
        </ul>

        <h2>3. How We Use Your Information</h2>
        <ul>
            <li>Provide, operate, and improve our Service</li>
            <li>Personalize your experience</li>
            <li>Process transactions and manage accounts</li>
            <li>Communicate with you about updates, promotions, and support</li>
            <li>Monitor usage patterns and enhance security</li>
            <li>Comply with legal and regulatory requirements</li>
        </ul>

        <h2>4. How We Share Your Information</h2>
        <p>We do <strong>not</strong> sell your personal information. However, we may share it with:</p>
        <ul>
            <li><strong>Service providers</strong> (e.g., hosting, analytics, payment processors)</li>
            <li><strong>Business partners</strong> (only for features requiring integration)</li>
            <li><strong>Legal authorities</strong> (if required by law or to enforce our policies)</li>
            <li><strong>Third parties in business transfers</strong> (e.g., merger, acquisition, asset sale)</li>
        </ul>

        <h2>5. Data Security</h2>
        <p>We implement security measures to protect your data, including encryption, access controls, and secure servers. However, no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security.</p>

        <h2>6. Your Rights & Choices</h2>
        <ul>
            <li>Accessing, correcting, or deleting your data</li>
            <li>Opting out of marketing communications</li>
            <li>Requesting data portability</li>
            <li>Restricting or objecting to data processing</li>
        </ul>
        <p>To exercise these rights, contact us at <strong>contact@leadcollect.ca</strong>.</p>

        <h2>7. Cookies & Tracking Technologies</h2>
        <p>We may use cookies and tracking tools to enhance your experience. You can control cookie preferences through your browser settings.</p>

        <h2>8. Third-Party Links & Integrations</h2>
        <p>Our Service may link to third-party websites or integrate with third-party tools. We are not responsible for their privacy practices.</p>

        <h2>9. Retention of Data</h2>
        <p>We retain personal data for as long as necessary to provide the Service, comply with legal obligations, or resolve disputes.</p>

        <h2>10. Changes to This Policy</h2>
        <p>We may update this Privacy Policy from time to time. Any changes will be posted with an updated effective date. Continued use of the Service after changes constitutes acceptance of the revised policy.</p>

        <h2>11. Contact Us</h2>
        <p>If you have questions or concerns about this Privacy Policy, please contact us at:</p>
        <p><strong>Email:</strong> contact@leadcollect.ca</p>

        <p><em>Last Updated: 2025-02-10</em></p>
    </div>
  )
};

export default PrivacyPolicy;
