import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../firebaseConfig";
import { collection, doc, getDoc, increment, updateDoc } from "firebase/firestore";
import ProgressBar from "./ProgressBar";

function QuizPage() {
    const { quizId } = useParams();
    const navigate = useNavigate();
    const [quizData, setQuizData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedChoice, setSelectedChoice] = useState('');
    const [answers, setAnswers] = useState([]); // Track answers
  
    useEffect(() => {
      async function fetchQuiz() {
        const quizRef = doc(collection(db, "quizzes"), quizId);
        const quizSnapshot = await getDoc(quizRef);
        
        if (quizSnapshot.exists()) {
          setQuizData(quizSnapshot.data());
        } else {
          console.error("No such quiz!");
        }
        
        setLoading(false);
      }
            const incrementViews = async () => {
              const docRef = doc(db, "quizzes", quizId);
              await updateDoc(docRef, {
                nbVisitors: increment(1),
              });
            };
        
            incrementViews();
      fetchQuiz();
    }, [quizId]);

  
    const handleChoiceChange = (choice) => {
      setSelectedChoice(choice);
    };
  
    const handleNext = () => {
      const existingAnswers = JSON.parse(localStorage.getItem("quizAnswers")) || [];
    
      const updatedAnswers = [
        ...existingAnswers,
        {
          question: quizData.questions[currentQuestionIndex].question,
          answer: selectedChoice,
        },
      ];
    
      localStorage.setItem("quizAnswers", JSON.stringify(updatedAnswers));
    
      if (currentQuestionIndex < quizData.questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setSelectedChoice(updatedAnswers[currentQuestionIndex + 1]?.answer || '');
      } else {
        navigate(`/quiz/${quizId}/leadName`); // Redirect to LeadName page
      }
    };
    

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="quiz-page">
    <ProgressBar
        currentQuestionIndex={currentQuestionIndex} 
        totalQuestions={quizData.questions.length} 
    />

    <div className="quiz-container">
        {/* Move title inside quiz-container */}
        {currentQuestionIndex === 0 && (
                <h2 className="quizTitle">{quizData.title}</h2>
        )}

        <p className="questionStyle">{quizData.questions[currentQuestionIndex].question}</p>

        <div className="choices-container">
            {quizData.questions[currentQuestionIndex].choices.map((choice, index) => (
                <div
                    key={index}
                    className={`choice-container ${selectedChoice === choice ? 'selected' : ''}`}
                    onClick={() => handleChoiceChange(choice)}
                >
                    <input
                        type="radio"
                        id={`choice-${index}`}
                        name="choice"
                        value={choice}
                        checked={selectedChoice === choice}
                        onChange={() => handleChoiceChange(choice)}
                        style={{ marginRight: '10px' }}
                    />
                    <label htmlFor={`choice-${index}`} style={{ fontSize: '19px' }}>{choice}</label>
                </div>
            ))}
        </div>

        <button onClick={handleNext} className='nextButton'>Next</button>
    </div>

    <a href="https://leadcollect.ca" target="_blank" rel="noopener noreferrer" className="poweredBy">
        Powered by LeadCollect
    </a>
</div>

  );
}

export default QuizPage;
