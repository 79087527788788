// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, createUserWithEmailAndPassword, browserLocalPersistence, setPersistence  } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';// TODO: Add SDKs for Firebase products that you want to use// TODO: Add SDKs for Firebase products that you want to use
import { getStorage } from "firebase/storage";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBI0eGEocSXMIm6YT0Mo8CvGoy3LG_itdM",
  authDomain: "leadapp-75b58.firebaseapp.com",
  projectId: "leadapp-75b58",
  storageBucket: "leadapp-75b58.appspot.com",
  messagingSenderId: "730839280853",
  appId: "1:730839280853:web:969368a95231330467c65c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

const provider = new GoogleAuthProvider();

setPersistence(auth, browserLocalPersistence);
export { app, db, auth, provider, createUserWithEmailAndPassword, storage  };