import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebaseConfig";
import App from "./App";
import Login from "./auth/Login";
import Signup from "./auth/Signup";
import LandingPage from "./LandingPage";
import Home from "./insidePages/Home";
import CreateLeadMagnet from "./insidePages/CreateLeadMagnet";
import QuizPage from "./QuizTemplate/QuizLanding";
import EmailPage from "./QuizTemplate/EmailPage";
import FinalPage from "./QuizTemplate/FinalPage";
import LeadMagnetPage from "./insidePages/LeadMagnetPage";
import LeadNamePage from "./QuizTemplate/LeadNamePage";
import Loading from "./auth/Loading";
import SuccessLoading from "./auth/SuccessLoading";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import OAuthSuccess from "./auth/OAuthSuccess";

function PrivateRoute({ element }) {
  const [user, loading] = useAuthState(auth);

  if (loading) return <div>Loading...</div>;
  return user ? element : <Navigate to="/landingPage" />;
}

function RootComponent() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/landingPage" element={<LandingPage />} />
        <Route path="/loading" element={<Loading/>} />

        {/* Protected Routes */}
        <Route path="/success" element={<SuccessLoading />} />
        <Route path="/home" element={<PrivateRoute element={<Home />} />} />
        <Route path="/createLeadMagnet" element={<PrivateRoute element={<CreateLeadMagnet />} />} />
        <Route path="/oauth-success" element={<OAuthSuccess />}  />

        <Route path="/quiz/:quizId" element={<QuizPage />} />
        <Route path="/quiz/:quizId/email" element={<EmailPage />} />
        <Route path="/quiz/:quizId/finalPage" element={<FinalPage />} />
        <Route path="/LeadMagnetPage/:quizName" element={<LeadMagnetPage />} />
        <Route path="/quiz/:quizId/leadName" element={<LeadNamePage />} />

        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />

        {/* Default Redirect */}
        <Route path="/" element={<Navigate to="/loading" />} />
      </Routes>
    </Router>
  );
}

export default RootComponent;
