import React from "react"

function WhatWeDo({text, subtext}) {
  return (
    <div className="whatWeDoTile">
      <p className="processText">{text}</p>
      <p className="processSubText">{subtext}</p>
    </div>
  )
};

export default WhatWeDo;
